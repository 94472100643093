'use client'
import {
  Modal,
  ModalContent,
  ModalBody,
  Button
} from '@nextui-org/react'
import { useState, useEffect } from 'react'
import RegisterInterview from '@/app/components/registration/RegisterInterview'

type MerchantType = {
  name: string
  id: string
  status: string
}

export const dynamic = 'force-dynamic'
export default function NewSubscriptionButton() {

  const [showCreationModal, setShowCreationModal] = useState<boolean>(false)

  return (
    <>
      <Button onClick={()=> { setShowCreationModal(true); }} size='sm' color='primary'>Subscribe</Button>
      <Modal 
          size={'5xl'} 
          className='bg-white'
          isOpen={showCreationModal} 
          onClose={()=> { setShowCreationModal(false); }} 
          isDismissable={false}
          scrollBehavior={'inside'}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody className='m-2'>
                  <RegisterInterview 
                    goToSubscribe
                    onChange={(range)=> {}}
                    subMerchant={true}
                  />
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
    </>
  )
}
