'use client'
import { Database } from '@/app/types/supabase'
import getClientSupabase from '@/app/utils/getClientSupabase'
import { useState, useEffect } from 'react'
import KpiCard from '@/app/components/home/kpiCard'
import clientGetCookies from '@/app/components/core/clientCookies'
import { merchantCredits } from '@/app/types/collection'
  
export default function HomeKpiMerchant({category}: {category:'merchant_commissions'|'merchant_credits'}) {

    const [loaded, setLoaded] = useState<boolean>(false);
    const [kpiComissionEur, setKpiComissionEur] = useState<string|undefined>()
    const [kpiComissionUsd, setKpiComissionUsd] = useState<string|undefined>()

    const [availableCredits, setAvailableCredits] = useState<merchantCredits|undefined>()

    useEffect(() => {
        if (!loaded) {
            const supabase = getClientSupabase()

            supabase.auth.getSession().then((result) => {
            const sessionUser = result.data.session?.user.app_metadata;
            const merchantIdLocal = clientGetCookies('set_merchant_id') || sessionUser?.merchant_id;
        
            if (category==='merchant_commissions') {
                supabase.rpc('merchant_commissions', { in_merchant_id: merchantIdLocal }).then((res) => {
                    if (res.data !== null) {
                        const result = res.data as { eur: number, usd: number }
                        setKpiComissionEur(result.eur.toFixed(2));
                        setKpiComissionUsd(result.usd.toFixed(2));
                    }
                    setLoaded(true);
                });
            } else {
                supabase.rpc('merchant_credits', { in_merchant_id: merchantIdLocal }).then((res) => {
                    if (res.data !== null) {
                        const result = res.data as merchantCredits
                        setAvailableCredits(result)
                    }
                    setLoaded(true);
                });
            }
        });
        }
    }, [])

    return (
    <>
        {(category==='merchant_commissions') &&
        <>
            <KpiCard title={'Outstanding Commission'} value={(kpiComissionEur?kpiComissionEur:'')} type={'currency'} loaded={kpiComissionEur!==undefined} />
            <KpiCard title={'Outstanding Commission'} value={(kpiComissionUsd?kpiComissionUsd:'')} type={'currencyUsd'} loaded={kpiComissionUsd!==undefined} />
        </>
        }
        {(category==='merchant_credits') &&
        <>
            {(availableCredits && availableCredits?.sponsorship_manager!==0) && <KpiCard title={'Sponsorships'} value={(availableCredits?.sponsorship_manager.toString())} type={'string'} loaded={availableCredits!==undefined} /> }
            {(availableCredits && availableCredits?.sponsorship_light!==0) && <KpiCard titleReact={<div>Sponsorships <sup>Light</sup></div>} value={(availableCredits?.sponsorship_light.toString())} type={'string'} loaded={availableCredits!==undefined} /> }
            {(availableCredits && availableCredits?.job_preparation_small!==0) && <KpiCard titleReact={<div>Job preparation <sup>small</sup></div>} value={(availableCredits?.job_preparation_small.toString())} type={'string'} loaded={availableCredits!==undefined} /> }
            {(availableCredits && availableCredits?.job_preparation_large!==0) && <KpiCard titleReact={<div>Job preparation <sup>large</sup></div>} value={(availableCredits?.job_preparation_large.toString())} type={'string'} loaded={availableCredits!==undefined} /> }
            {(availableCredits && availableCredits?.coaching_small!==0) && <KpiCard titleReact={<div>Coaching <sup>small</sup></div>} value={(availableCredits?.coaching_small.toString())} type={'string'} loaded={availableCredits!==undefined} /> }
            {(availableCredits && availableCredits?.coaching_medium!==0) && <KpiCard titleReact={<div>Coaching <sup>medium</sup></div>} value={(availableCredits?.coaching_medium.toString())} type={'string'} loaded={availableCredits!==undefined} /> }
            {(availableCredits && availableCredits?.coaching_unlimited!==0) && <KpiCard titleReact={<div>Coaching <sup>unlimited</sup></div>} value={(availableCredits?.coaching_unlimited.toString())} type={'string'} loaded={availableCredits!==undefined} /> }
        </>
        }
    </>
    )
}
